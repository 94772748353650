<template>
  <deviceBorrowItem
    class="allDevice"
     loaddingClass="allDevice"
    :rowKeyList="rowKeyList"
    :keyList="keyList"
    :queryType="8"
  ></deviceBorrowItem>
</template>

<script>
import deviceBorrowItem from "@/components/deviceBorrowItem";
export default {
  name: "allDevice",
  components: { deviceBorrowItem },
  data() {
    return {
      keyList: [
        {
          key: "orderId",
          name: "预约单号",
          space: "21px",
          float: "right",
          color: "#3E73FB",
        },
        {
          key: "orderTime",
          name: "预约时间",
          space: "21px",
          float: "right",
          color: "#3E73FB",
        },
      ],
      rowKeyList: [
        { key: "brandName", name: "品 牌", space: "21px", float: "right" },
        { key: "modelName", name: "型 号", space: "21px", float: "right" },
        { key: "code", name: this.$VUEAPPDEVICECODE(), space: "21px", float: "right" },
        { key: "label", name: "卡 片 号", space: "3px", float: "right" },
        {
          key: "categoryName",
          name: "设备分类",
          space: "21px",
          float: "right",
        },
        {
          key: "depreciationMethod",
          name: "折旧方式",
          space: "21px",
          float: "right",
        },
        {
          key: "yearLimit",
          name: "折 旧",
          space: "21px",
          float: "right",
        },
      ],
    };
  },
  beforeDestroy() {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
</style>
